import React from 'react';
import { withPrefix, graphql } from 'gatsby';

// Layouts
import Default from '../layouts/default';
import Seo from '../layouts/seo';

// Components
import TagList from '../components/tag-list';
import PageHeader from '../components/page-header';
import Section from '../components/section';
import Author from '../components/author';
import PostControls from '../components/post-controls';
import Content, { HTMLContent } from "../components/content-renderer";

// Styles
import '../sass/layouts/article.scss';

// Assets
import ArticleImage from '../assets/images/content/blog-post.svg';

const BlogPost = ({ data, pageContext }) => {
  const { markdownRemark: post } = data;
  const PageContent = HTMLContent || Content;
  const headerImage = post.frontmatter.image.relativePath;

  const { previous, next } = pageContext;

  return (
    <Default>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description}
      />

      <PageHeader
        heading={post.frontmatter.title}
				imageSource={post.frontmatter.image ? `${withPrefix("/")}img/${headerImage}` : ArticleImage}
				description={post.frontmatter.description}
        breadcrumbs={[
          {name: 'Home', url: '/'},
          {name: 'Articles', url: '/articles'},
        ]}
      />

      <Section
        className='article__wrapper'
        label='Article body and information'
        variants={['padded']}
      >
        <section className='article__information container' aria-label='Article information'>
          <div className='article__information-inner'>
            <Author
              author={post.frontmatter.author}
              date={post.frontmatter.date}
            />

            {post.frontmatter.tags && (
              <TagList tags={post.frontmatter.tags} prepend='Tags:' />
            )}
          </div>
        </section>

        <section className='article__content container content-renderer' aria-label='Article body'>
          <PageContent content={post.html} />
        </section>
        
        <PostControls
          previous={previous}
          next={next}
        />
      </Section>
    </Default>
  );
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        image {
          relativePath
        }
        author
        tags
      }
    }
  }
`;
